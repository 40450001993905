/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */

import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { Physics } from '@react-three/rapier';
import { LwsDie, LwsPlane } from './RapierDiceComponents';
import { Vector3 } from 'three';

export default function Scene({ renderDice = [], updateDataDiceRef = (id, dieRef) => {}, forwardDiceRefs }) {
  const [boxWidth, setBoxWidth] = useState(1000);
  const [boxDepth, setBoxDepth] = useState(1000);
  const [minBoxSize, setMinBoxSize] = useState(1000);
  const [dieColor, setDieColor] = useState('#666666');
  const [floorColor, seFloorColor] = useState('#222222');
  const [dice, setDice] = useState([]);

  const boxHeight = 4;

  const { width, height } = useThree(state => state.viewport);

  function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end;
  }

  function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
  }

  useEffect(() => {
    const calcBoxWidth = width - 1;
    const calcBoxDepth = height - 1;
    const calcMinBoxSize = Math.min(calcBoxWidth, calcBoxDepth);

    setBoxWidth(calcBoxWidth);
    setBoxDepth(calcBoxDepth);
    setMinBoxSize(calcMinBoxSize);
  });

  useEffect(() => {
    makeDice();
  }, [renderDice]);

  function makeDice() {
    const returnDice = [];
    console.log(minBoxSize);
    const maxSize = minBoxSize/8;
    const minSize = maxSize * 0.45;
    const maxNDice = 75;
    const minNDice = 1;

    const dieSizeRatio = clamp((renderDice.length - minNDice) / (maxNDice - minNDice), 0, 1);
    const size = lerp(maxSize, minSize, dieSizeRatio);

    for (const die of renderDice) {
      returnDice.push(
        <LwsDie
          key={`cube-${Date.now}-${die.id}`}
          size={size}
          color={dieColor}
          id={die.id}
          boxWidth={boxWidth}
          boxDepth={boxDepth}
          dieSizeRatio={dieSizeRatio}
          boxHeight={boxHeight}
          updateDataDiceRef={updateDataDiceRef}
          ref={forwardDiceRefs.current[die.id]}
        />
      );
    }

    setDice(returnDice);
  }

  return (
    <Suspense>
      <pointLight
        position={[0, boxHeight - 0.5, 0]}
        castShadow
        shadow-radius={8}
        shadow-mapSize={[1024, 1024]}
        intensity={1}
      />
      <ambientLight intensity={0.3} />
      <Physics>
        {/* bottom */}
        <LwsPlane args={[boxWidth, 1, boxDepth]} textured color={floorColor} opacity={1} position={[0, -0.5, 0]} />
        {/* ===================== Invisible bounding box ===================== */}
        {/* top */}
        <LwsPlane args={[boxWidth, 1, boxDepth]} position={[0, boxHeight + 0.5, 0]} />
        {/* right */}
        <LwsPlane args={[2, boxHeight, boxDepth + 4]} color={'#ff0000'} position={[boxWidth / 2 + 1, boxHeight / 2, 0]} />
        {/* left */}
        <LwsPlane args={[2, boxHeight, boxDepth + 4]} color={'#ffff00'} position={[-boxWidth / 2 - 1, boxHeight / 2, 0]} />
        {/* back */}
        <LwsPlane args={[boxWidth + 4, boxHeight, 2]} color={'#00ff00'} position={[0, boxHeight / 2, -boxDepth / 2 - 1]} />
        {/* front */}
        <LwsPlane args={[boxWidth + 4, boxHeight, 2]} color={'#0000ff'} position={[0, boxHeight / 2, boxDepth / 2 + 1]} />
        {/* TODO: Die out of box detection + re-roll using sensors */}
        {/* ===================== Invisible bounding box ===================== */}
        {dice}
      </Physics>
    </Suspense>
  );
}
