/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import { LwsButton, LwsInput, LwsLabeledInput, LwsLabeledRefInput } from '../../components/BasicComponents';
import Scene from './RapierDiceRollerScene';
import { Canvas } from '@react-three/fiber';
import { calculateAvailableHands, HANDS } from './DiceUtils';
import { OrbitControls } from '@react-three/drei';

export default function RapierDiceRoller() {
  const dataDiceRefs = useRef([]);
  const forwardDiceRefs = useRef([]);
  const inputRef = useRef(null);

  const [hasRolled, setHasRolled] = useState(false);
  const [diceSettled, setDiceSettled] = useState(false);
  const [diceSelected, setDiceSelected] = useState(false);
  const [renderDice, setRenderDice] = useState([]);

  const [rollTotal, setRollTotal] = useState(0);
  const [possibleHands, setPossibleHands] = useState([]);

  const [num1Result, setNum1Result] = useState(0);
  const [num2Result, setNum2Result] = useState(0);
  const [num3Result, setNum3Result] = useState(0);
  const [num4Result, setNum4Result] = useState(0);
  const [num5Result, setNum5Result] = useState(0);
  const [num6Result, setNum6Result] = useState(0);

  const [rollsUsed, setRollsUsed] = useState(0);

  const updateInputValue = e => {
    inputRef.current.value = e.target.value;
  };

  const createDataDice = numDice => {
    const newDice = [];
    forwardDiceRefs.current = [];
    dataDiceRefs.current = [];
    for (let i = 0; i < numDice; i++) {
      newDice.push({ id: i, value: 0, isSelected: false });
      forwardDiceRefs.current.push(React.createRef());
      dataDiceRefs.current.push(React.createRef());
    }

    setRenderDice(newDice);
  };

  const countDiceValues = () => {
    let tempRollTotal = 0;
    let tempNum1Result = 0;
    let tempNum2Result = 0;
    let tempNum3Result = 0;
    let tempNum4Result = 0;
    let tempNum5Result = 0;
    let tempNum6Result = 0;

    for (const dieRef of dataDiceRefs.current) {
      const die = dieRef.current.userData;
      tempRollTotal += die.value;
      switch (die.value) {
        case 1:
          tempNum1Result++;
          break;
        case 2:
          tempNum2Result++;
          break;
        case 3:
          tempNum3Result++;
          break;
        case 4:
          tempNum4Result++;
          break;
        case 5:
          tempNum5Result++;
          break;
        case 6:
          tempNum6Result++;
          break;
      }
    }

    setRollTotal(tempRollTotal);
    setNum1Result(tempNum1Result);
    setNum2Result(tempNum2Result);
    setNum3Result(tempNum3Result);
    setNum4Result(tempNum4Result);
    setNum5Result(tempNum5Result);
    setNum6Result(tempNum6Result);
  };

  useEffect(() => {
    setPossibleHands(calculateAvailableHands(num1Result, num2Result, num3Result, num4Result, num5Result, num6Result));
  }, [rollTotal]);

  const updateDataDiceRef = (id, dieRef) => {
    dataDiceRefs.current[id] = dieRef;
    const areDiceSettled = dataDiceRefs.current.every(dieRef => dieRef.current?.userData?.value > 0);
    const areDiceSelected = dataDiceRefs.current.some(dieRef => dieRef.current?.userData?.isSelected);
    if (areDiceSettled) {
      setDiceSettled(true);
      setDiceSelected(areDiceSelected);
      countDiceValues();
    }
  };

  const clearResults = () => {
    setRollTotal(0);
    setNum1Result(0);
    setNum2Result(0);
    setNum3Result(0);
    setNum4Result(0);
    setNum5Result(0);
    setNum6Result(0);
  };

  const rollButtonClicked = () => {
    if (inputRef.current.value <= 0) {
      clearButtonClicked();
    } else {
      setRollsUsed(rollsUsed + 1);
      setDiceSettled(false);
      setDiceSelected(false);
      setHasRolled(true);
      createDataDice(inputRef.current.value);
      clearResults();
    }
  };

  const reRollButtonClicked = () => {
    setRollsUsed(rollsUsed + 1);
    setDiceSettled(false);
    setDiceSelected(false);
    setHasRolled(true);
    clearResults();
    for (const die of dataDiceRefs.current) {
      if (die.current.userData.isSelected) {
        forwardDiceRefs.current[die.current.userData.id].current.reRollDie();
      }
    }
  };

  const clearButtonClicked = () => {
    setRollsUsed(0);
    setDiceSettled(false);
    setDiceSelected(false);
    setHasRolled(false);
    createDataDice(0);
    clearResults();
  };

  const selectDiceOfValue = (value = 0) => {
    for (const die of dataDiceRefs.current) {
      if (value === 0 || die.current.userData.value === value) {
        forwardDiceRefs.current[die.current.userData.id].current.selectDie();
      }
    }
  };

  return (
    <div className='rapier-dice-roller'>
      <div className='rapier-dice-box'>
        <Canvas shadows camera={{ position: [0, 20, 0], fov: 33 }}>
          {/* <OrbitControls /> */}
          <Scene renderDice={renderDice} updateDataDiceRef={updateDataDiceRef} forwardDiceRefs={forwardDiceRefs} />
        </Canvas>
      </div>
      <div className='rapier-dice-controls'>
        <LwsButton disabled={!diceSettled || num1Result === 0} onClick={() => selectDiceOfValue(1)}>
          Select 1s
          <br />( {num1Result} )
        </LwsButton>
        <LwsButton disabled={!diceSettled || num2Result === 0} onClick={() => selectDiceOfValue(2)}>
          Select 2s
          <br />( {num2Result} )
        </LwsButton>
        <LwsButton disabled={!diceSettled || num3Result === 0} onClick={() => selectDiceOfValue(3)}>
          Select 3s
          <br />( {num3Result} )
        </LwsButton>
        <LwsButton disabled={!diceSettled || num4Result === 0} onClick={() => selectDiceOfValue(4)}>
          Select 4s
          <br />( {num4Result} )
        </LwsButton>
        <LwsButton disabled={!diceSettled || num5Result === 0} onClick={() => selectDiceOfValue(5)}>
          Select 5s
          <br />( {num5Result} )
        </LwsButton>
        <LwsButton disabled={!diceSettled || num6Result === 0} onClick={() => selectDiceOfValue(6)}>
          Select 6s
          <br />( {num6Result} )
        </LwsButton>
        <LwsButton disabled={!diceSettled} onClick={() => selectDiceOfValue()}>
          Select All
          <br />( {num1Result + num2Result + num3Result + num4Result + num5Result + num6Result} )
        </LwsButton>
        <LwsLabeledInput readOnly label='Total' value={rollTotal} />
        <LwsLabeledInput readOnly label='Rolls' value={rollsUsed} />
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
            gap: '8px',
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          <strong>Possible Hands</strong>
          <br />
          {possibleHands.includes(HANDS.TWO_LOW) && <span>2 Low</span>}
          {possibleHands.includes(HANDS.TWO_MID) && <span>2 Mid</span>}
          {possibleHands.includes(HANDS.TWO_HIGH) && <span>2 High</span>}
          {possibleHands.includes(HANDS.PAIR) && <span>Pair</span>}
          {possibleHands.includes(HANDS.TWO_PAIR) && <span>2 Pair</span>}
          {possibleHands.includes(HANDS.THREE_LOW) && <span>3 Low</span>}
          {possibleHands.includes(HANDS.THREE_MID) && <span>3 Mid</span>}
          {possibleHands.includes(HANDS.THREE_HIGH) && <span>3 High</span>}
          {possibleHands.includes(HANDS.THREE_OAK) && <span>3 of a Kind</span>}
          {possibleHands.includes(HANDS.SMALL_STRAIGHT) && <span>Small Straight</span>}
          {possibleHands.includes(HANDS.LARGE_STRAIGHT) && <span>Large Straight</span>}
          {possibleHands.includes(HANDS.FULL_HOUSE) && <span>Full House</span>}
          {possibleHands.includes(HANDS.FOUR_LOW) && <span>4 Low</span>}
          {possibleHands.includes(HANDS.FOUR_MID) && <span>4 Mid</span>}
          {possibleHands.includes(HANDS.FOUR_HIGH) && <span>4 High</span>}
          {possibleHands.includes(HANDS.FOUR_OAK) && <span>4 of a Kind</span>}
          {possibleHands.includes(HANDS.FIVE_LOW) && <span>5 Low</span>}
          {possibleHands.includes(HANDS.FIVE_MID) && <span>5 Mid</span>}
          {possibleHands.includes(HANDS.FIVE_HIGH) && <span>5 High</span>}
          {possibleHands.includes(HANDS.FIVE_OAK) && <span>5 of a Kind</span>}
          {possibleHands.includes(HANDS.TRIACONTA) && <span>Triaconta</span>}
        </div> */}
      </div>
      <div className='rapier-dice-menu'>
        <LwsLabeledRefInput
          label='# Dice'
          className={'--min-content'}
          onChange={e => updateInputValue(e)}
          defaultValue={6}
          ref={inputRef}
          min={0}
          type='number'
        />
        <LwsButton disabled={hasRolled} onClick={rollButtonClicked}>
          Roll
        </LwsButton>
        <LwsButton disabled={!hasRolled /*|| (hasRolled && !diceSettled)*/} onClick={clearButtonClicked}>
          Clear
        </LwsButton>
        <LwsButton disabled={!diceSelected} onClick={reRollButtonClicked}>
          Reroll
        </LwsButton>
        {/* <LwsButton disabled={!diceSelected} onClick={() => console.log('Store')}>
          Store
        </LwsButton> */}
        {/* <LwsButton
          onClick={() => {
            console.info({
              dieRefs: dataDiceRefs.current,
              forwardRefs: forwardDiceRefs.current,
              rollTotal,
              possibleHands,
              hasRolled,
              diceSettled,
            });
          }}
        >
          Debug
        </LwsButton> */}
      </div>
    </div>
  );
}
