/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import { CuboidCollider, RigidBody } from '@react-three/rapier';
import feltTexture from '../../images/felt.jpg';
import die1 from '../../images/Die1Center.png';
import die2 from '../../images/Die2Center.png';
import die3 from '../../images/Die3Center.png';
import die4 from '../../images/Die4Center.png';
import die5 from '../../images/Die5Center.png';
import die6 from '../../images/Die6Center.png';
import die1Pips from '../../images/Die1PipPlain.png';
import die2Pips from '../../images/Die2PipPlain.png';
import die3Pips from '../../images/Die3PipPlain.png';
import die4Pips from '../../images/Die4PipPlain.png';
import die5Pips from '../../images/Die5PipPlain.png';
import die6Pips from '../../images/Die6PipPlain.png';
import { Raycaster, Vector3 } from 'three';

export function LwsPlane({
  args,
  color = '#AAA',
  opacity = 0,
  rotation = [0, 0, 0],
  position = [0, 0, 0],
  textured = false,
}) {
  const texture = useTexture(feltTexture);
  return (
    <RigidBody type='fixed' colliders='trimesh' rotation={rotation} position={position}>
      <mesh receiveShadow>
        <boxGeometry args={args ?? [25, 0.1, 25]} />
        {textured ? (
          <meshStandardMaterial color={color} opacity={opacity} transparent map={texture} />
        ) : (
          <meshPhongMaterial color={color} opacity={opacity} transparent />
        )}
      </mesh>
    </RigidBody>
  );
}

export const LwsDie = forwardRef(function LwsDie(
  {
    size = 1,
    color = '#aaa',
    id = -1,
    shouldApplyImpulse = true,
    updateDataDiceRef = (id, dieRef) => {},
    dieSizeRatio = 1,
    boxHeight = 2,
    boxWidth = 1,
    boxDepth = 1,
  },
  ref
) {
  const usePips = true;
  const textureArray = usePips
    ? [die1Pips, die6Pips, die3Pips, die4Pips, die5Pips, die2Pips]
    : [die1, die6, die3, die4, die5, die2];
  const initialData = { id: id, isSelected: false, value: 0 };

  const dieRef = useRef();
  const meshRef = useRef();
  const normalizedDirection = useRef(new Vector3());
  const impulsePower = useRef(0.1);
  const torquePower = useRef(0.1);
  const sleepProcessed = useRef(false);

  const [calculatedColor, setCalculatedColor] = useState(color);

  const diceTextures = useTexture(textureArray);
  const dieMaterialValues = [1, 6, 3, 4, 5, 2];
  const idleColor = '#aaa';
  const selectedColor = '#ffa420';
  const impulseStrength = 2;
  const torqueStrength = 1.5;

  useImperativeHandle(ref, () => ({
    selectDie: () => {
      dieClicked();
    },
    reRollDie: () => {
      reRollDie();
    },
  }));

  useEffect(() => {
    if (dieRef.current) {
      dieRef.current.userData = initialData;
      if (shouldApplyImpulse) {
        setInitialPhysicsState();
      }
    }
  }, []);

  // TODO: make custom sleep detector as the default timing can't be changed and is too long for my tastes (default is 3 seconds, would prefer 1)
  const onSleep = () => {
    if (!sleepProcessed.current) {
      sleepProcessed.current = true;
      freezeDie();
      const thisDie = meshRef.current.parent;
      const diePos = thisDie.position;
      const rayOrigin = new Vector3(diePos.x, diePos.y + 1, diePos.z);
      const rayDirection = new Vector3(0, -2, 0);
      const raycast = new Raycaster(rayOrigin, rayDirection);
      const hit = raycast.intersectObject(thisDie);

      let thisValue = -1;
      if (hit.length > 0) {
        // TODO: Cracked die detection + re-roll
        thisValue = dieMaterialValues[hit[0].face.materialIndex];
        dieRef.current.userData.value = thisValue;
        updateDataDiceRef(id, dieRef);
        setCalculatedColor(dieRef.current.userData.isSelected ? selectedColor : idleColor);
      }
    }
  };

  const getNewDieOrigin = () => {
    const safeHeight = Math.max(2, boxHeight) - size * 2;
    const randomAngle = Math.random() * Math.PI * 2;

    const curX = Math.cos(randomAngle) * ((boxWidth - size * 2) / 2);
    const randY = Math.random() * safeHeight + size; // Give room at top and bottom for safety padding if size is 1, and height is 6, this is 1-5
    const curZ = Math.sin(randomAngle) * ((boxDepth - size * 2) / 2);
    return new Vector3(curX, randY, curZ);
  };

  const getNewTargetPosition = dieOrigin => {
    const randomWidth = Math.random() * boxWidth;
    const randomDepth = Math.random() * boxDepth;
    const adjustedX = randomWidth - boxWidth/2;
    const adjustedY = randomDepth - boxDepth/2;

    const adjustedRatio = Math.min(1, dieSizeRatio * 2);
    
    const targetX = adjustedX * adjustedRatio; // (Math.cos(randomTargetAngle) * ((boxWidth - size * 2) / 2)) / targetSizeRatio;
    const targetZ = adjustedY * adjustedRatio; // (Math.sin(randomTargetAngle) * ((boxDepth - size * 2) / 2)) / targetSizeRatio;
    const targetY = dieOrigin.y;

    return new Vector3(targetX, targetY, targetZ);
  };

  const setInitialPhysicsState = () => {
    // -------------------------------- Position and Rotation --------------------------------
    const dieOrigin = getNewDieOrigin();

    const randRoll = Math.random() * 90;
    const randPitch = Math.random() * 90;
    const randYaw = Math.random() * 90;
    const dieRotation = new Vector3(randRoll, randPitch, randYaw);

    // -------------------------------- Force and Target --------------------------------
    const targetPos = getNewTargetPosition(dieOrigin);

    const direction = targetPos.sub(dieOrigin);
    const targetLength = direction.length();
    normalizedDirection.current = direction.normalize();

    const halfImpulseStrength = impulseStrength / 2;
    const randomImpulseStrength = halfImpulseStrength * Math.random() + halfImpulseStrength;
    impulsePower.current = targetLength * randomImpulseStrength * size ** 3;

    const halfTorqueStrength = impulseStrength / 2;
    const randomTorqueStrength = halfTorqueStrength * Math.random() + halfTorqueStrength;
    torquePower.current = randomTorqueStrength * size ** 4;

    moveDie(dieOrigin);
    rotateDie(dieRotation);
    // freezeDie();
    rollDie(calculateTargetVector());
  };

  const calculateTargetVector = () =>
    new Vector3(
      normalizedDirection.current.x * impulsePower.current,
      normalizedDirection.current.y * impulsePower.current,
      normalizedDirection.current.z * impulsePower.current
    );

  const moveDie = locVector => {
    dieRef.current.setTranslation(locVector);
  };

  const rotateDie = rotVector => {
    dieRef.current.setRotation(rotVector);
  };

  const rollDie = dirVector => {
    sleepProcessed.current = false;
    thawDie();
    dieRef.current.applyImpulse(
      {
        x: dirVector.x,
        y: dirVector.y,
        z: dirVector.z,
      },
      true
    );
    dieRef.current.applyTorqueImpulse(
      {
        x: Math.random() * torquePower.current,
        y: Math.random() * torquePower.current,
        z: Math.random() * torquePower.current,
      },
      true
    );
  };

  const reRollDie = () => {
    dieRef.current.userData.isSelected = false;
    dieRef.current.userData.value = 0;
    updateDataDiceRef(id, dieRef);
    setCalculatedColor(color);
    setInitialPhysicsState();
  };

  const dieClicked = e => {
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();
    if (sleepProcessed) {
      const newSelected = !dieRef.current.userData.isSelected;
      dieRef.current.userData.isSelected = newSelected;
      updateDataDiceRef(id, dieRef);
      setCalculatedColor(dieRef.current.userData.isSelected ? selectedColor : idleColor);
    }
  };

  const freezeDie = () => {
    dieRef.current.setEnabledTranslations(false, false, false, false);
    dieRef.current.setEnabledRotations(false, false, false, false);
  };

  const thawDie = () => {
    dieRef.current.setEnabledTranslations(true, true, true, true);
    dieRef.current.setEnabledRotations(true, true, true, true);
  };

  const returnDie = (
    // TODO: Convert to instanced mesh
    <RigidBody
      ref={dieRef}
      colliders={false}
      onClick={dieClicked}
      position={[0, 1, 0]}
      linearDamping={1}
      angularDamping={0.01}
      restitution={0.75 * size}
      onSleep={onSleep}
    >
      <mesh ref={meshRef} castShadow>
        <boxGeometry args={[size, size, size]} />
        <CuboidCollider args={[size / 2, size / 2, size / 2]} />
        {diceTextures.map((texture, idx) => (
          <meshStandardMaterial key={texture.id} color={calculatedColor} attach={`material-${idx}`} map={texture} />
        ))}
      </mesh>
    </RigidBody>
  );

  return returnDie;
});
