import React, { forwardRef } from 'react';

export const LwsButton = props => {
  const { onClick, className, type, disabled = false } = props;
  return (
    <button disabled={disabled} className={`lws-button ${className ?? ''}`} type={type ?? 'button'} onClick={onClick}>
      {props.children}
    </button>
  );
};

export const LwsInput = props => {
  const { name, className, readOnly } = props;
  return <input readOnly={readOnly} id={name} {...props} className={`lws-input ${className ?? ''}`} />;
};

export const LwsLabeledInput = props => {
  const { onChange, value, className, label, name, type, step, min, max, style, placeholder, defaultValue, readOnly } =
    props;
  return (
    <div style={style} className={`lws-labeled-input ${className ?? ''}`}>
      <label htmlFor={name}>{label}</label>
      <LwsInput
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        className='--labeled'
        name={name}
        type={type}
        step={step}
        min={min}
        max={max}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
};

export const LwsRefInput = forwardRef(function LwsRefInput(props, ref) {
  const { name, className } = props;
  return <input id={name} {...props} ref={ref} className={`lws-input ${className ?? ''}`} />;
});

export const LwsLabeledRefInput = forwardRef(function LwsLabeledRefInput(props, ref) {
  const { onChange, value, className, label, name, type, step, min, max, style, placeholder, defaultValue } = props;
  return (
    <div style={style} className={`lws-labeled-input ${className ?? ''}`}>
      <label htmlFor={name}>{label}</label>
      <LwsRefInput
        value={value}
        defaultValue={defaultValue}
        ref={ref}
        onChange={onChange}
        className='--labeled'
        name={name}
        type={type}
        step={step}
        min={min}
        max={max}
        placeholder={placeholder}
      />
    </div>
  );
});

export const LwsSelect = props => {
  const { onChange, value, className, children } = props;
  return (
    <select className={`lws-select ${className ?? ''}`} value={value} onChange={onChange}>
      {children}
    </select>
  );
};

export const LwsLabeledSelect = props => {
  const { onChange, value, className, children, name, label } = props;
  return (
    <div className={`lws-labeled-input ${className ?? ''}`}>
      <label htmlFor={name}>{label}</label>
      <LwsSelect value={value} onChange={onChange} className='--labeled'>
        {children}
      </LwsSelect>
    </div>
  );
};

export const LwsTextArea = props => {
  const { onChange, value, className, rows, style, placeholder } = props;
  return (
    <textarea
      className={`lws-text-area ${className ?? ''}`}
      style={style}
      value={value}
      rows={rows}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};
