import React from 'react';

function App() {
  return (
    <>
      <h1>About me</h1>
      <div style={{ padding: '0 16px 16px 16px' }}>
        <p className='about-section'>
          <p>
            I am Grayson Lorenz, professional code wizard. I love my work and to be creative. On the side I play D&D and
            have a small wood shop business: <a href='https://LorenzWoodShop.com'>Lorenz Wood Shop</a>
          </p>
          <p>
            This website, you're on now, serves as a place for me to put the apps my friends ask me for that I think
            would be fun, or things I use personally. This is where I work on becoming a better developer of tools and
            UI/UX.
          </p>
        </p>
        <p className='about-section'>
          <h2>Very Long Story Short</h2>
          <p>
            I've been a professional developer since 2015 and have been working with Java, C#, .Net Core, MySQL,
            PostgreSQL, MongoDB, Javascript (Node, Express, Vue and React), PHP, HTML and CSS.
          </p>
          <p>
            My current expertise is in TypeScript, React, CSS, PostgreSQL, and Node. I use this for most projects I work
            on do personally, including this site. I have an eye for UI/UX and like rolling my own css.
          </p>
          <p>
            I have worked on EMR (Electronic Medical Records) systems, creating APIs, magazine content management
            systems, a streaming service for rodeo content, and much more.
          </p>
          <p>
            If you, can think it, I can build it. Even if it's hard to articulate. My number one priority is being a
            steward of my projects. I care about the success of the spirit of my projects.
          </p>
        </p>
      </div>
    </>
  );
}

export default App;
