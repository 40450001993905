export const HANDS = Object.freeze({
  PAIR: Symbol('PAIR'),
  TWO_PAIR:  Symbol('TWO_PAIR'),
  TWO_LOW: Symbol('TWO_LOW'),
  TWO_MID: Symbol('TWO_MID'),
  TWO_HIGH: Symbol('TWO_HIGH'),
  THREE_OAK: Symbol('THREE_OAK'),
  SMALL_STRAIGHT: Symbol('SMALL_STRAIGHT'),
  THREE_LOW: Symbol('THREE_LOW'),
  THREE_MID: Symbol('THREE_MID'),
  THREE_HIGH: Symbol('THREE_HIGH'),
  FULL_HOUSE: Symbol('FULL_HOUSE'),
  LARGE_STRAIGHT: Symbol('LARGE_STRAIGHT'),
  FOUR_LOW: Symbol('FOUR_LOW'),
  FOUR_MID: Symbol('FOUR_MID'),
  FOUR_HIGH: Symbol('FOUR_HIGH'),
  FOUR_OAK: Symbol('FOUR_OAK'),
  FIVE_LOW: Symbol('FIVE_LOW'),
  FIVE_MID: Symbol('FIVE_MID'),
  FIVE_HIGH: Symbol('FIVE_HIGH'),
  FIVE_OAK: Symbol('FIVE_OAK'),
  TRIACONTA: Symbol('TRIACONTA'),
});

export function calculateAvailableHands(num1Result, num2Result, num3Result, num4Result, num5Result, num6Result) {
  const resultArray = [num1Result, num2Result, num3Result, num4Result, num5Result, num6Result];
  const possibleHands = [];

  /* -------------------- LOWS -------------------- */
  if (num1Result + num2Result === 2) {
    possibleHands.push(HANDS.TWO_LOW);
  }
  if (num1Result + num2Result === 3) {
    possibleHands.push(HANDS.THREE_LOW);
  }
  if (num1Result + num2Result === 4) {
    possibleHands.push(HANDS.FOUR_LOW);
  }
  if (num1Result + num2Result >= 5) {
    possibleHands.push(HANDS.FIVE_LOW);
  }

  /* -------------------- MIDS -------------------- */
  if (num3Result + num4Result === 2) {
    possibleHands.push(HANDS.TWO_MID);
  }
  if (num3Result + num4Result === 3) {
    possibleHands.push(HANDS.THREE_MID);
  }
  if (num3Result + num4Result === 4) {
    possibleHands.push(HANDS.FOUR_MID);
  }
  if (num3Result + num4Result >= 5) {
    possibleHands.push(HANDS.FIVE_MID);
  }

  /* -------------------- HIGHS -------------------- */
  if (num5Result + num6Result === 2) {
    possibleHands.push(HANDS.TWO_HIGH);
  }
  if (num5Result + num6Result === 3) {
    possibleHands.push(HANDS.THREE_HIGH);
  }
  if (num5Result + num6Result === 4) {
    possibleHands.push(HANDS.FOUR_HIGH);
  }
  if (num5Result + num6Result >= 5) {
    possibleHands.push(HANDS.FIVE_HIGH);
  }

  /* -------------------- KINDS -------------------- */
  let pairs = 0;
  let oak3s = 0;
  for (const result of resultArray) {
    if (result >= 3) {
      oak3s++;
    } else if (result >= 2) {
      pairs++;
    }
  }
  if (pairs >= 1) {
    possibleHands.push(HANDS.PAIR);
  }
  if (pairs > 1) {
    possibleHands.push(HANDS.TWO_PAIR);
  }
  if (resultArray.some(x => x === 3)) {
    possibleHands.push(HANDS.THREE_OAK);
  }
  if (resultArray.some(x => x === 4)) {
    possibleHands.push(HANDS.FOUR_OAK);
  }
  if (resultArray.some(x => x >= 5)) {
    possibleHands.push(HANDS.FIVE_OAK);
  }
  if ((pairs > 0 && oak3s > 0) || oak3s > 1) {
    possibleHands.push(HANDS.FULL_HOUSE);
  }

  /* -------------------- STRAIGHTS -------------------- */
  if (
    (num1Result && num2Result && num3Result && num4Result) ||
    (num2Result && num3Result && num4Result && num5Result) ||
    (num3Result && num4Result && num5Result && num6Result)
  ) {
    possibleHands.push(HANDS.SMALL_STRAIGHT);
  }
  if (
    (num1Result && num2Result && num3Result && num4Result && num5Result) ||
    (num2Result && num3Result && num4Result && num5Result && num6Result)
  ) {
    possibleHands.push(HANDS.LARGE_STRAIGHT);
  }

  /* -------------------- TRIACONTA -------------------- */
  if (num6Result >= 5) {
    possibleHands.push(HANDS.TRIACONTA);
  }

  return possibleHands;
}

export function statsTest() {
  let low2Results = 0;
  let low3Results = 0;
  let low4Results = 0;
  let low5Results = 0;

  let mid2Results = 0;
  let mid3Results = 0;
  let mid4Results = 0;
  let mid5Results = 0;

  let high2Results = 0;
  let high3Results = 0;
  let high4Results = 0;
  let high5Results = 0;

  let pairResults = 0;
  let pair2Results = 0;
  let oak3Results = 0;
  let oak4Results = 0;
  let oak5Results = 0;
  let fullHouseResults = 0;

  let smallStraightResults = 0;
  let largeStraightResults = 0;

  let triacontaResults = 0;
  const randomDie = () => Math.floor(Math.random() * (6 - 1 + 1) + 1);

  const numTries = 10000;
  for (let i = 0; i < numTries; i++) {
    const results = [];
    // Simulate 3 rolls
    for (let j = 0; j < 3; j++) {
      if (results.length < 6) {
        const hand = [];
        // reroll only what isnt set aside
        for (let k = 0; k < 6 - results.length; k++) {
          hand.push(randomDie());
        }
        // keep 5 and 6 on first 2 rolls only
        for (const res of hand) {
          if (j <= 1) {
            if (res >= 5 && results.length < 6) {
              results.push(res);
            }
          } else {
            results.push(res);
          }
        }
      }
    }
    // console.log('final results', results);

    let tempNum1Result = 0;
    let tempNum2Result = 0;
    let tempNum3Result = 0;
    let tempNum4Result = 0;
    let tempNum5Result = 0;
    let tempNum6Result = 0;

    for (const die of results) {
      switch (die) {
        case 1:
          tempNum1Result++;
          break;
        case 2:
          tempNum2Result++;
          break;
        case 3:
          tempNum3Result++;
          break;
        case 4:
          tempNum4Result++;
          break;
        case 5:
          tempNum5Result++;
          break;
        case 6:
          tempNum6Result++;
          break;
      }
    }

    const resultArray = [
      tempNum1Result,
      tempNum2Result,
      tempNum3Result,
      tempNum4Result,
      tempNum5Result,
      tempNum6Result,
    ];
    const non6Array = [tempNum1Result, tempNum2Result, tempNum3Result, tempNum4Result, tempNum5Result];

    /* -------------------- LOWS -------------------- */
    if (tempNum1Result + tempNum2Result === 2) {
      low2Results++;
    }
    if (tempNum1Result + tempNum2Result === 3) {
      low3Results++;
    }
    if (tempNum1Result + tempNum2Result === 4) {
      low4Results++;
    }
    if (tempNum1Result + tempNum2Result >= 5) {
      low5Results++;
    }

    /* -------------------- MIDS -------------------- */
    if (tempNum3Result + tempNum4Result === 2) {
      mid2Results++;
    }
    if (tempNum3Result + tempNum4Result === 3) {
      mid3Results++;
    }
    if (tempNum3Result + tempNum4Result === 4) {
      mid4Results++;
    }
    if (tempNum3Result + tempNum4Result >= 5) {
      mid5Results++;
    }

    /* -------------------- HIGHS -------------------- */
    if (tempNum5Result + tempNum6Result === 2) {
      high2Results++;
    }
    if (tempNum5Result + tempNum6Result === 3) {
      high3Results++;
    }
    if (tempNum5Result + tempNum6Result === 4) {
      high4Results++;
    }
    if (tempNum5Result + tempNum6Result >= 5) {
      high5Results++;
    }

    /* -------------------- KINDS -------------------- */
    let pairs = 0;
    let oak3s = 0;
    for (const result of resultArray) {
      if (result >= 3) {
        oak3s++;
      } else if (result >= 2) {
        pairs++;
      }
    }
    if (pairs >= 1) {
      pairResults++;
    }
    if (pairs > 1) {
      pair2Results++;
    }
    if (resultArray.some(x => x === 3)) {
      oak3Results++;
    }
    if (resultArray.some(x => x === 4)) {
      oak4Results++;
    }
    if (non6Array.some(x => x >= 5)) {
      oak5Results++;
    }
    if ((pairs > 0 && oak3s > 0) || oak3s > 1) {
      fullHouseResults++;
    }

    /* -------------------- STRAIGHTS -------------------- */
    if (
      (tempNum1Result && tempNum2Result && tempNum3Result && tempNum4Result) ||
      (tempNum2Result && tempNum3Result && tempNum4Result && tempNum5Result) ||
      (tempNum3Result && tempNum4Result && tempNum5Result && tempNum6Result)
    ) {
      smallStraightResults++;
    }
    if (
      (tempNum1Result && tempNum2Result && tempNum3Result && tempNum4Result && tempNum5Result) ||
      (tempNum2Result && tempNum3Result && tempNum4Result && tempNum5Result && tempNum6Result)
    ) {
      largeStraightResults++;
    }

    /* -------------------- TRIACONTA -------------------- */
    if (tempNum6Result >= 5) {
      triacontaResults++;
    }
  }
  console.log('pairResults', (pairResults / numTries) * 100);
  console.log('pair2Results', (pair2Results / numTries) * 100);
  console.log('flush2Results', (Math.max(low2Results, mid2Results, high2Results) / numTries) * 100);
  console.log('oak3Results', (oak3Results / numTries) * 100);
  console.log('smallStraightResults', (smallStraightResults / numTries) * 100);
  console.log('flush3Results', ((low3Results + mid3Results + high3Results) / numTries) * 100);
  console.log('fullHouseResults', (fullHouseResults / numTries) * 100);
  console.log('largeStraightResults', (largeStraightResults / numTries) * 100);
  console.log('flush4Results', (Math.max(low4Results, mid4Results, high4Results) / numTries) * 100);
  console.log('oak4Results', (oak4Results / numTries) * 100);
  console.log('flush5Results', (Math.max(low5Results, mid5Results, high5Results) / numTries) * 100);
  console.log('oak5Results', (oak5Results / numTries) * 100);
  console.log('triacontaResults', (triacontaResults / numTries) * 100);
}